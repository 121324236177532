import React from "react";
import { translations } from "../base.js";
import { RoomApi } from "../api/roomApi.js";

const RoomInfo = ({ room }) => {
    const { closeRoom } = RoomApi;

    const handleClose = async () => {
        await closeRoom(room.id);
        window.location.href = "";
    };

    return (
        room && (
            <div className="widget-container">
                <div className="widget room">
                    <div className="widget-content">
                        <div className="title">
                            {translations.Rooms.Room}: {room.name}
                        </div>

                        {room.description?.split("\n").map((item, key) => {
                            return <p key={key}>{item}</p>;
                        })}
                    </div>
                </div>
            </div>
        )
    );
};

export default RoomInfo;
