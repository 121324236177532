import React, { useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import {
    RouterProvider,
    useLocation,
    createBrowserRouter,
    Outlet,
} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { SITE_LANGUAGE, SITE_URL, translations } from "./base.js";
import ChildrenPage from "./pages/ChildrenPage";
import UserChildrenPage from "./pages/UserChildrenPage.jsx";
import TopChildrenPage from "./pages/TopChildrenPage.jsx";
import ParentsPage from "./pages/ParentsPage.jsx";
import UserParentsPage from "./pages/UserParentsPage.jsx";
import FavoritesPage from "./pages/FavoritesPage.jsx";
import RegisterPage from "./pages/RegisterPage.jsx";
import LoginPage from "./pages/LoginPage.jsx";
import ConfirmEmailPage from "./pages/ConfirmEmailPage.jsx";
import ChangePasswordPage from "./pages/ChangePasswordPage.jsx";
import ResetPasswordPage from "./pages/ResetPasswordPage.jsx";
import ConfirmResetPasswordPage from "./pages/ConfirmResetPasswordPage.jsx";
import UserPage from "./pages/UserPage.jsx";
import UsersPage from "./pages/UsersPage.jsx";
import NewsPage from "./pages/NewsPage.jsx";
import NewsSinglePage from "./pages/NewsSinglePage.jsx";
import BadgesPage from "./pages/BadgesPage.jsx";
import ContactsPage from "./pages/ContactsPage.jsx";
import NotFoundPage from "./pages/NotFoundPage.jsx";
import FeedbacksPage from "./pages/FeedbacksPage.jsx";
import AboutPage from "./pages/AboutPage.jsx";
import NotificationsPage from "./pages/NotificationsPage.jsx";
import ChildPage from "./pages/ChildPage.jsx";
import ParentPage from "./pages/ParentPage.jsx";
import MessagesPage from "./pages/MessagesPage.jsx";
import SearchPage from "./pages/SearchPage.jsx";
import RoomsPage from "./pages/RoomsPage.jsx";
import RoomParentsPage from "./pages/RoomParentsPage.jsx";
import RoomChildrenPage from "./pages/RoomChildrenPage";

import NovelPage from "./pages/NovelPage.jsx";

import Header from "./pages/partials/Header.jsx";
import Footer from "./pages/partials/Footer.jsx";
import NewsWidget from "./widgets/NewsWidget.jsx";

import "./App.scss";

const Layout = () => {
    const location = useLocation();
    const [title, setTitle] = useState(translations.Menu.Home);

    useEffect(() => {
        const siteMap = [
            { path: "/", title: translations.Menu.Home },
            { path: "/about", title: translations.Menu.About },
            { path: "/children", title: translations.Menu.Children },
            { path: "/mysetups", title: translations.Menu.MyParents },
            { path: "/my", title: translations.Menu.MyChildren },
            { path: "/top", title: translations.Menu.Top },
            { path: "/feedbacks", title: translations.Menu.Feedbacks },
            { path: "/contacts", title: translations.Menu.Contacts },
            { path: "/messages", title: translations.Menu.Messages },
            { path: "/badges", title: translations.Menu.Badges },
            { path: "/users", title: translations.Menu.Users },
            { path: "/news", title: translations.Menu.News },
            { path: "/favorites", title: translations.Menu.Favorites },
            { path: "/login", title: translations.Auth.Login },
            { path: "/register", title: translations.Auth.Register },
            { path: "/reset-password", title: translations.Auth.ResetPassword },
            {
                path: "/confirm-reset-password",
                title: translations.Auth.ConfirmResetPassword,
            },
            {
                path: "/changepassword",
                title: translations.Auth.ChangePassword,
            },
            { path: "/user", title: translations.Menu.User },
            { path: "/confirm-email", title: translations.Menu.ConfirmEmail },
            { path: "/notifications", title: translations.Menu.Notifications },
            { path: "/search", title: translations.Menu.Search },
            { path: "/rooms", title: translations.Menu.Rooms },
            { path: "/roomsp", title: translations.Menu.RoomParents },
            { path: "/roomsc", title: translations.Menu.RoomChildren },
        ];

        const currentPageTitle = siteMap.find(
            (item) =>
                item.path === location.pathname ||
                (item.path !== "/" && location.pathname.includes(item.path))
        );

        if (currentPageTitle && currentPageTitle.title) {
            setTitle(currentPageTitle.title);
        } else {
            setTitle(translations.Menu.Home);
        }
    }, [location]);

    return (
        <div className="pageContainer">
            <Helmet>
                <link
                    rel="canonical"
                    href={`${SITE_URL}${location.pathname}`}
                />

                {title && <title>{title}</title>}
            </Helmet>

            <Header />

            {location.pathname === "/" && <NewsWidget />}

            <main>
                <Outlet />
            </main>

            <Footer />
        </div>
    );
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: "/",
                element: <ParentsPage />,
            },
            {
                path: "/parents/:type?",
                element: <ParentsPage />,
            },
            {
                path: "/about",
                element: (
                    <AboutPage
                        title={translations.Menu.About}
                        lang={SITE_LANGUAGE}
                    />
                ),
            },
            {
                path: "/mysetups",
                element: <UserParentsPage />,
            },
            {
                path: "children",
                element: <ChildrenPage />,
            },
            {
                path: "my/:type?",
                element: <UserChildrenPage />,
            },
            {
                path: "userchs/:id?/:type?",
                element: <UserChildrenPage />,
            },
            {
                path: "userprs/:id?",
                element: <UserParentsPage />,
            },
            {
                path: "top/:type?",
                element: <TopChildrenPage />,
            },
            {
                path: "register",
                element: <RegisterPage />,
            },
            {
                path: "confirm-email/:hash/:email",
                element: <ConfirmEmailPage />,
            },
            {
                path: "changepassword",
                element: <ChangePasswordPage />,
            },
            {
                path: "login",
                element: <LoginPage />,
            },
            {
                path: "resetpassword",
                element: <ResetPasswordPage />,
            },
            {
                path: "confirm-reset-password/:hash/:email",
                element: <ConfirmResetPasswordPage />,
            },
            {
                path: "favorites",
                element: <FavoritesPage />,
            },
            {
                path: "user/:id?",
                element: <UserPage />,
            },
            {
                path: "users",
                element: <UsersPage />,
            },
            {
                path: "news",
                element: <NewsPage />,
            },
            {
                path: "news/:id",
                element: <NewsSinglePage />,
            },
            {
                path: "badges",
                element: <BadgesPage />,
            },
            {
                path: "contacts",
                element: <ContactsPage />,
            },
            {
                path: "feedbacks",
                element: <FeedbacksPage />,
            },
            {
                path: "child/:id/:type?",
                element: <ChildPage />,
            },
            {
                path: "parent/:id",
                element: <ParentPage />,
            },
            {
                path: "notifications",
                element: <NotificationsPage />,
            },
            {
                path: "messages/:recipientId?",
                element: <MessagesPage />,
            },
            {
                path: "search",
                element: <SearchPage />,
            },
            {
                path: "rooms",
                element: <RoomsPage />,
            },
            {
                path: "roomp/:roomId",
                element: <RoomParentsPage />,
            },
            {
                path: "roomc/:roomId",
                element: <RoomChildrenPage />,
            },
            {
                path: "/notfound",
                element: <NotFoundPage />,
            },

            {
                path: "/novels/part/:id",
                element: <NovelPage />,
            },
            {
                path: "*",
                element: <NotFoundPage />,
            },
        ],
    },
]);

function App() {
    return (
        <HelmetProvider>
            <RouterProvider router={router} />
        </HelmetProvider>
    );
}

export default App;
